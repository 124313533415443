<template>
  <div class="con-wrap">
    <MCGViewPopup
      :idx="mcg.idx"
      :idx_hq="mcg.idx_hq"
      v-if="mcg.isShow"
      @close="mcg.isShow = false"
    ></MCGViewPopup>
    <CarrotTitle title="MCG 관리">
      - 리드(센터장) 직급 이상의 리더에게 MCG 작성을 독려하기 위한
      페이지입니다.<br />
      - MCG 필수 작성 수는 [팀원 수(본인 산하 직원 수)X 12회(연 MCG 최소 작성
      수)] 입니다.
    </CarrotTitle>
    <div class="board">
      <div v-if="bbs.isAuth == true" class="mt-40">
        <div>
          <div class="mb-20">
            <select
              class="w-100px float-left mr-10"
              v-model="bbs.syear"
              @change="bbs.doInit"
            >
              <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">
                {{ v }}
              </option>
            </select>

            <CarrotSector   v-model="bbs.sector"
                            @change="bbs.doInit"
                            class="w-200px mr-10">

            </CarrotSector>

            <CarrotDept
              v-model="bbs.office"
              @change="bbs.doInit"
              class="w-200px mr-10"
            ></CarrotDept>
            <CarrotTeam
              v-model="bbs.team"
              :idx_office="bbs.office"
              @change="bbs.doInit"
              class="w-200px"
            ></CarrotTeam>
            <div class="clear"></div>
          </div>

          <table class="table-col">
            <!-- <colgroup>
              <col width="50" />
              <col width="*" />
              <col width="140" />
              <col width="100" />
              <col width="150" />
              <col width="52" v-for="i in 12" :key="i" />
            </colgroup> -->
            <thead>
              <tr>
                <th width="70">No</th>
                <th width="130">부문</th>
                <th width="130">본부</th>
                <th width="130">팀</th>
                <th width="130">이름</th>
                <th v-for="i in 12" :key="i">{{ i }}월</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in bbs.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>
                  <p class="line-clamp-1">{{ irow.sector_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.office_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.team_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">
                    {{ irow.ename }} ({{ irow.kname }})
                  </p>
                </td>
                <td v-for="s in 12" :key="s">

                  <span v-if="irow.month && irow.month[s < 10 ? '0' + s : s]"  >
                    <span v-if="bbs.isTotalMcgMcr" style="cursor:pointer" @click="  mcg.showMCG(irow.month[s < 10 ? '0' + s : s], irow.idx_hq)">
                      O
                    </span>
                    <span v-else>
                      O
                    </span>
                  </span>
                  <span v-else>X</span>

                </td>
              </tr>
              <tr v-if="bbs.total == 0">
                <td colspan="17">항목이 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="bbs.total"
            :list_per_page="bbs.rows"
            v-model="bbs.page"
            @change="bbs.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import MCGViewPopup from "@/components/popup/myPIMS/MCGViewPopup.vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import CarrotSector from "@/components/common/CarrotSector";

export default {
  layout: "myPIMS",

  components: {
    MCGViewPopup,
    CarrotSector
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const bbs = reactive({
      isMounted: false,
      isAuth: false,
      search_years: [],

      page: 1,
      rows: 50,

      syear: "",


      sector: "0",
      office: "0",
      team: "0",
      isTotalMcgMcr:false,

      list: [],
      total: 0,

      doInit: () => {
        bbs.page = 1;
        bbs.doSearch();
      },

      doSearch: () => {
        if (bbs.isMounted == false) return;
        let params = {
          page: bbs.page,
          rows: bbs.rows,

          syear: bbs.syear,
          sector: bbs.sector,
          office: bbs.office,
          team: bbs.team,
          kind: "MCG",
        };
        axios
          .get("/rest/mypims/getMCGRList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;

              bbs.isAuth = res.data.is_auth == "Y" ? true : false;

              if (bbs.isAuth == false) {
                Swal.fire({
                  title: "MCG 관리",
                  text: "권한이 없습니다.",
                }).then(() => {
                  router.go(-1);
                });
              }
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      makeSearchYears: () => {
        let cd = new Date();
        let cy = cd.getFullYear();
        for (let i = cy; i >= 2019; i--) {
          bbs.search_years.push(i);
        }
      },
    });

    const mcg = reactive({
      idx: 0,
      idx_hq: 0,
      // Popup 노출 여부
      isShow: false,

      showMCG: (idx, idx_hq) => {
        mcg.idx = idx;
        (mcg.idx_hq = idx_hq), (mcg.isShow = true);
      },
    });

    onMounted(() => {
      if (store.state.pd_auth == false) {
        router.go(-1);
        Swal.fire({
          title: "MCG 관리",
          text: "접근 권한이 없습니다.",
        });
        return;
      }

      bbs.isTotalMcgMcr = store.state.isTotalMcgMcr;
      let td = new Date();
      bbs.syear = td.getFullYear();

      bbs.makeSearchYears();
      bbs.isMounted = true;
      bbs.doInit();
    });

    return { bbs, mcg };
  },
};
</script>

<style lang="scss" scoped></style>
